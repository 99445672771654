<template>
  <v-container fluid>
    <h4 class="text-h4 secondary--text">Emisión de CDP</h4>
    <h6 class="text-h6">Ejercicio {{ anioFiscal }}</h6>

    <h6 class="text-h6 secondary--text mt-8">{{ proceso?.codigo_proceso }}</h6>
    <h4 class="text-h4 secondary--text">{{ proceso?.nombre_proceso }}</h4>

     <v-form ref="formCdp" v-if="!enableDownload || editarCdp">
      <v-row class="mt-8">
        <v-col cols="12" sm="4" md="5">
          <v-text-field
            outlined
            v-model="numeroCdp"
            label="Número de CDP *"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="4" md="5">
          <v-file-input
            outlined
            required
            prepend-icon=""
            v-model="comprobante"
            accept="application/pdf"
            prepend-inner-icon="mdi-paperclip"
            label="Comprobante de nombramiento *"
            :rules="[rules.required, rules.type, rules.name, rules.size]"
          ></v-file-input>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-btn
            block
            class="mt-2"
            color="secondary"
            @click="showModalEmitirCdp = true"
          >
            Emitir CDP
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <div v-else class="mt-4 mb-4">
      <h5 class="text-h5">Número de CDP: {{ proceso?.numero_cdp }} <a @click="downloadCdp" class="info--text">(Descargar documento <v-icon color="info">mdi-download</v-icon>)</a></h5>
      <div>
        <v-btn
          rounded
          class="mt-2"
          @click="editarCdp = true"
        >
          <v-icon>mdi-pencil</v-icon>
          <span>Editar CDP</span>
        </v-btn>
      </div>
    </div>

    <p>Listado de solicitudes</p>
    <AdminDocumentosComponent
      :etapa="etapa"
      :permisos="{ leer: true }"
      :extra-params="{ id_institucion: selectedUnidad?.instituciones?.id }"
      v-if="[1].includes(etapa?.id_tipo_configuracion)"
    />

    <ConfirmationDialogComponent
      :show="showModalEmitirCdp"
      btnConfirmar="Continuar"
      :title="`¿Desea emitir el CDP para este proceso?`"
      message="Esta acción es irreversible, haga click en 'Continuar' para ejecutar"
      @close="showModalEmitirCdp = false"
      @confirm="emitirCdp()"
    />

    <v-row class="mt-16 mb-16">
      <v-col cols="12" sm="3">
        <v-btn
          block
          outlined
          @click="$router.back()"
        >
          Volver
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import AdminDocumentosComponent from "./components/Etapas/AdminDocumentosComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

const maxFileSizeRule = LegacyValidations.maxFileSizeRule();

export default {
  name: 'AsignacionCdpProcesoView',
  components: { AdminDocumentosComponent, ConfirmationDialogComponent },
  data: () => ({
    proceso: null,
    rules: {
      required: value => !!value || 'Este es un campo requerido',
      type: value => fileTypeValid(value) || 'Ingrese un documento válido, se aceptan las siguientes extensiones: PDF',
      name: value => fileNameValid(value) || 'Ingrese un nombre de archivo válido, no se aceptan caracteres especiales',
      size: value => {
        const rule = maxFileSizeRule(value);

        return rule.valid || rule.error;
      },
    },
    numeroCdp: null,
    comprobante: null,
    etapa: null,
    showModalEmitirCdp: false,
    editarCdp: false,
  }),
  computed: {
    ...mapState(["anioFiscal", "selectedUnidad"]),
    enableDownload() {
      return (
        this.proceso?.numero_cdp !== null 
        && this.proceso?.numero_cdp !== ''
        && this.proceso?.documento_cdp !== null
        && this.proceso?.documento_cdp !== ''
      )
    }
  },
  methods: {
    async getProceso() {

      const response = await this.services.PacProcesos.getProceso(this.$route.params.idProceso)
      if (response.status === 200) this.proceso = response.data;

    },

    async getEtapaSolicitudes() {

      const { status, data } =
        await this.services.PacProcesos.getEtapaSolicitudes(
          this.$route.params.idProceso,
          3
        )

      if (status == 200) {
        this.etapa = data[0];

      }
    },
    
    async emitirCdp() {
      if (this.$refs.formCdp.validate()) {

        const form = new FormData();
        form.append('numeroCdp', this.numeroCdp);
        form.append('documento', this.comprobante);

        const response = await this.services.PacProcesos.emitirCdp(this.$route.params.idProceso, form);

        if (response.status === 200) { 
          this.temporalAlert({
            show: true,
            type: 'success',
            message: 'Se ha almacenado el cdp para este proceso',
          });
          this.$router.replace({ name: 'pac-procesos' });
        }
      }
    },
    
    async downloadCdp() {

      const { status, data, headers } =
        await this.services.Usuarios.descargarTitulo({
          ruta: this.proceso.documento_cdp,
          disk: "procesos",
        })

      if (status === 200) {
        const blob = new Blob([data], {
          type: headers["content-type"],
        });

        const file = new File(
          [blob],
          `tituloNombramiento_${this.proceso.id}`,
          {
            type: headers["content-type"],
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();
      }


    }
  },
  async created() {
    await this.getProceso();
    await this.getEtapaSolicitudes();
  },
}
</script>